import * as React from "react"
import {useState} from "react";
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout";
import Layout from "../components/Layout";
import DoubleBlock from "../components/doubleBlock/DoubleBlock";
import HelpChoosing from "../components/offers/HelpChoosing";
import {SEO} from "../components/seo";
import FormPopup from "../components/popups/FormPopup";
import {AnimatePresence} from "framer-motion";


export default function Review({location}) {
    const [formPopup, setFormPopup] = useState(false)

    const helpChoosingData = {
        title: 'Получить полную информацию о курсе',
        text: 'Остались вопросы? Оставьте свой номер, мы позвоним и ответим на все ваши вопросы'
    }

    return (
        <>
            <SEO />
            <Layout classAdd="photoPage" location={location}>
                <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup}/>}>

                    <HelpChoosing data={helpChoosingData} inside={true}/>
                </DoubleBlock>
            </Layout>

            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}
                onExitComplete={() => null}
            >
                {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
            </AnimatePresence>
        </>
    )
}